/* Custom Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e8e7e7;
  border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000;
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* ---------------------------------------------------------------------- */
