/* TailwindCss */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    body {
        @apply bg-[#F5F5F5]
    }
}

@layer components {
    .label-form {
        @apply text-[16px] text-label font-normal leading-[19.41px]
    }

    .input-form {
        @apply px-4 max-w-full text-sm border-[1px] focus:border-[2px] border-[#A5A5A5] h-[50px] border-solid focus:border-black focus:bg-neutral-50 focus:outline-none rounded-md
    }

    .input-date {
        @apply w-full bg-white border focus:border-[2px] border-[#A5A5A5] focus:border-black focus:bg-neutral-50 text-sm px-4 h-[50px] focus:outline-none rounded-md
    }

    .input-select {
        @apply text-black h-[50px] px-4 text-sm focus:bg-neutral-50 focus:outline-black focus:outline-[2px] block bg-white border-r-8 outline border-r-transparent outline-1 outline-[#A5A5A5] rounded-md
    }

    .btn-primary {
        @apply w-full h-[50px] bg-black text-white hover:shadow-md hover:opacity-90 active:bg-gray-900 focus:outline-none rounded-md
    }

    .btn-secondary {
        @apply w-full h-[50px] bg-white text-black border border-black hover:shadow-md hover:opacity-90 active:bg-gray-100 focus:outline-none rounded-md
    }

    .btn-data-table {
        @apply w-fit h-fit px-4 py-1.5 bg-primary rounded text-white hover:shadow-xl hover:opacity-90 active:bg-gray-900 focus:outline-none
    }

    .btn-icon {
        @apply bg-white w-fit h-full py-0.5 px-2 flex flex-row justify-center items-center gap-1.5 rounded focus:outline-none text-black hover:shadow-xl active:bg-gray-200
    }

    .table-header {
        @apply px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left
    }
}